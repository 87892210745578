import { watch, ref, inject } from 'vue';
import { Ref } from '@vue/reactivity';

export interface ParamsValidateMessages {
  empty?: string;
}

export interface FormMultiSelectValidateParams {
  value: any;
  validateOff: boolean;
  required: boolean;
  validateMessages?: ParamsValidateMessages;
}

export interface FormMultiSelectValidateResult {
  value: Ref<string | Record<string, unknown>>;
  validate: Ref<boolean>;
  validateMessage: Ref<string>;
  setValidate: () => boolean | void;
}

export default function formInputValidate(params: FormMultiSelectValidateParams): FormMultiSelectValidateResult {
  const trigger: any = inject('validateInputs') as Record<string, unknown>;
  const value = params.value;

  const validateMessages = {
    empty: params.validateMessages?.empty ?? 'Поле не заполнено',
  };

  const validate = ref(true);
  const validateMessage = ref('');

  const setError = (message: string) => {
    validate.value = false;
    validateMessage.value = message;
  };

  const setSuccess = () => {
    validate.value = true;
    validateMessage.value = '';
  };

  const setValidate = () => {
    if (params.validateOff) {
      return false;
    }

    //empty
    if (params.required && value.value.length === 0) {
      setError(validateMessages.empty);

      return;
    }

    setSuccess();
  };

  watch(value, setValidate);
  watch(trigger, setValidate);

  return {
    value,
    validate,
    validateMessage,
    setValidate,
  };
}
