import { ref, computed, onMounted, nextTick } from 'vue';
import { Ref } from '@vue/reactivity';
import { ComputedRef } from '@vue/reactivity/dist/reactivity';
import { FormSelectUIResult } from '@/lib/formFactory/select/ui';
import { FormMultiSelectUIResult } from '@/lib/formFactory/multiSelect/ui';
import { MultiSelectItem } from '@/interfaces/multiSelect.interface';
import { escapeRegExpSpecialChars } from '@/lib/Utils';

export interface FormMultiSelectHandlerParams {
  emit: (eventName: string, eventData: unknown) => void;
  value: Ref<unknown[]>;
  modelValue: Ref<unknown[]>;
  dataName?: string | number;
  keyValue?: string;
  isOrder?: boolean;
  items: Ref<unknown[]>;
  isSearchEnabled: Ref<boolean>;
  isSearchActive: Ref<boolean>;
  filterListInput: Ref<HTMLInputElement | null>;
  ui: FormSelectUIResult | FormMultiSelectUIResult;
}

export interface FormMultiSelectHandlerResult {
  list: () => unknown[];
  filter: (event: { target: HTMLInputElement }) => void;
  update: (item?: Record<string, unknown>) => void;
  enableSearch: () => void;
  clearSearch: () => void;
  clear: (items: MultiSelectItem[]) => void;
  value: Ref<unknown[]>;
  prepareValue: ComputedRef<string>;
  searchString: Ref<string>;
  isSearchActive: Ref<boolean>;
}

export default function formMultiSelectHandler(params: FormMultiSelectHandlerParams): FormMultiSelectHandlerResult {
  const modelValue = ref(params.value);
  const items = ref(params.items);
  const filterListInput = ref(params.filterListInput);
  const searchString = ref('');
  const isSearchEnabled = ref(params.isSearchEnabled);
  const isSearchActive = ref(params.isSearchActive);

  const list = () => {
    const normalizedSearchString = escapeRegExpSpecialChars(searchString.value.toLowerCase());

    return items.value.filter((item: any) => {
      return item.name.toLowerCase().search(normalizedSearchString) !== -1;
    });
  };

  const filter = (event: { target: HTMLInputElement }) => {
    searchString.value = event.target.value;
  };

  const enableSearch = () => {
    if (isSearchEnabled.value) {
      isSearchActive.value = true;

      nextTick(() => {
        filterListInput.value?.focus();
        searchString.value = filterListInput.value?.value || '';
        params.ui.addClassIn();
      });
    }
  };

  const clearSearch = () => {
    isSearchActive.value = false;
    searchString.value = '';

    if (filterListInput.value) {
      filterListInput.value.value = '';
    }
  };

  const prepareValue = computed(() => {
    if (!params.modelValue.value.length) {
      return '';
    }

    return params.modelValue.value
      .map((item: unknown) => {
        if (typeof item === 'object' && item !== null) {
          if (params.keyValue) {
            return params.keyValue in item ? (item as Record<string, unknown>)[params.keyValue] : '';
          }

          return 'value' in item ? (item as { value: string }).value : '';
        } else {
          return '';
        }
      })
      .join(', ');
  });

  const prepareModelValue = () => {
    const result: Record<string, unknown> = {};

    modelValue.value.forEach((item: unknown, index: number) => {
      if (!(typeof item === 'object' && item !== null && 'id' in item)) {
        return;
      }

      result[`${params.dataName}[${params.isOrder ? index : (item as { id: unknown }).id}]`] = (item as {
        id: unknown;
      }).id;
    });

    return result;
  };

  const update = (item?: Record<string, unknown>) => {
    params.emit('update:modelValue', modelValue);
    params.emit('update:modifyValue', prepareModelValue());
    params.emit('update', modelValue);

    if (item) {
      params.emit('updateItem', {
        id: item.id,
        value: item[params.keyValue || 'value'],
      });
    }
  };

  const clear = (items: MultiSelectItem[]) => {
    params.emit('update:modelValue', modelValue);
    params.emit('update:modifyValue', prepareModelValue());
    params.emit('update', modelValue);
    if (Array.isArray(items)) {
      items.forEach(function (item) {
        if (item) {
          const key = params.keyValue || 'value';
          params.emit('updateItem', {
            id: item.id,
            value: (item as any)[key],
          });
        }
      });
    }
    modelValue.value = modelValue.value.filter((itemValue) => (itemValue as MultiSelectItem).checked) as MultiSelectItem[];
  };

  onMounted(update);

  return {
    list,
    filter,
    update,
    enableSearch,
    clearSearch,
    clear,
    value: modelValue,
    prepareValue,
    searchString,
    isSearchActive,
  };
}
