
import { ref, toRefs, defineComponent, PropType, watch } from 'vue';

import uiMultiSelectApi from '@/lib/formFactory/multiSelect/ui';
import handlerMultiSelectApi from '@/lib/formFactory/multiSelect/handler';
import validateMultiSelectApi from '@/lib/formFactory/multiSelect/validate';

import outsideSelectApi from '@/lib/formFactory/select/outside';

import nameTextFieldApi from '@/lib/formFactory/textField/name';
import rulesTextFieldApi from '@/lib/formFactory/textField/rules';
import errorTextFieldApi from '@/lib/formFactory/textField/error';

import { ParamsValidateMessages } from '@/lib/formFactory/select/validate';

import ArrowDown from '@/components/icons/ArrowDown.vue';

export default defineComponent({
  name: 'form-multi-select',
  emits: ['update:modelValue', 'update:modifyValue', 'update:isError', 'updateItem', 'update', 'validate'],
  props: {
    modelValue: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    value: { type: String },
    disabled: { type: Boolean },
    label: { type: String },
    readonly: { type: Boolean },
    name: { type: String },
    dataName: { type: String },
    id: { type: String },
    hint: { type: String },
    labelActive: { type: Boolean },
    validateOff: { type: Boolean },
    validateMessages: { type: Object as PropType<ParamsValidateMessages> },
    required: { type: Boolean },
    error: { default: '', type: [String, Boolean] },
    errorMessages: { default: '', type: [String, Array] },
    rules: { type: Function as PropType<() => boolean | string> },
    icon: { type: String },
    iconSize: { type: String },
    marginBottom: { type: Boolean },
    hideErrorMessages: { type: Boolean },
    filter: { type: Boolean },
    paddingTopZero: { type: Boolean },
    keyValue: { type: String },
    placeholder: { type: String },
    isOrder: { type: Boolean },
    items: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    isSearchEnabled: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  setup(props, { emit }) {
    const propsRefs = toRefs(props);

    const modelValue = propsRefs.modelValue;
    const propError = propsRefs.error;
    const propErrorMessages = propsRefs.errorMessages;

    const selectedItems = modelValue.value.length ? ref(modelValue.value) : ref([]);

    watch(propsRefs.modelValue, () => {
      selectedItems.value = propsRefs.modelValue.value;
    });

    const isSearchEnabled = propsRefs.isSearchEnabled;
    const isSearchActive = ref(false);

    const root = ref();
    const filterListInput = ref();

    const startY = ref<number | null>(null);
    const listWrapper = ref<HTMLElement>();

    const startResize = (event: MouseEvent) => {
      startY.value = event.clientY;
      document.addEventListener('mousemove', resizeList);
      document.addEventListener('mouseup', endResize);
      document.body.style.userSelect = 'none';
    };

    const endResize = () => {
      document.removeEventListener('mousemove', resizeList);
      document.removeEventListener('mouseup', endResize);
      document.body.style.userSelect = '';
    };

    const resizeList = (event: MouseEvent) => {
      if (startY.value !== null && listWrapper.value) {
        const delta = event.clientY - startY.value;
        const currentHeight = listWrapper?.value.clientHeight;

        if (currentHeight !== undefined && listWrapper.value) {
          const style = listWrapper.value?.style;

          if (style) {
            style.height = 'auto';
            style.height = `${currentHeight + delta}px`;
          }
        }

        startY.value = event.clientY;
      }
    };

    const ui = uiMultiSelectApi({ modelValue, isSearchActive });

    const handler = handlerMultiSelectApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      value: selectedItems,
      modelValue,
      dataName: props.dataName,
      keyValue: props.keyValue,
      isOrder: props.isOrder,
      items: propsRefs.items,
      isSearchEnabled,
      isSearchActive,
      filterListInput,
      ui,
    });

    const nameResult = nameTextFieldApi({
      dataName: propsRefs.dataName,
      label: propsRefs.label,
    });

    const validate = validateMultiSelectApi({
      value: handler.prepareValue,
      validateOff: props.validateOff,
      required: props.required,
      validateMessages: props.validateMessages,
    });

    const rulesResult = rulesTextFieldApi({
      value: handler.value,
      rules: props.rules,
    });

    const errorResult = errorTextFieldApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      error: propError,
      errorMessages: propErrorMessages,
      validate: validate.validate,
      validateMessage: validate.validateMessage,
      rules: rulesResult.validate,
      rulesMessage: rulesResult.validateMessage,
    });

    const outside = outsideSelectApi({
      emit: emit as (eventName: string, eventData: unknown) => void,
      ref: root,
      ui,
      handler,
      modelValue,
      validate,
      type: 'multi',
    });

    return {
      selectedItems,
      propError,
      propErrorMessages,
      root,
      filterListInput,
      ui,
      handler,
      outside,
      validate,
      rulesResult,
      errorResult,
      nameResult,
      startResize,
      endResize,
      resizeList,
      listWrapper,
    };
  },
  components: {
    ArrowDown,
  },
});
