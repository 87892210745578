import { ref } from 'vue';
import { Ref } from '@vue/reactivity';

export interface FormMultiSelectUIParams {
  modelValue: Ref<unknown[]>;
  isSearchActive: Ref<boolean>;
}

export interface FormMultiSelectUIResult {
  isLabelActive: Ref<boolean>;
  visibleItems: Ref<boolean>;
  addClassIn: () => void;
  removeClassIn: () => void;
  showItems: () => void;
  hideItems: () => void;
  toggle: () => void;
  getSelected: (id: string | number) => unknown;
}

export default function formMultiSelectUI(params: FormMultiSelectUIParams): FormMultiSelectUIResult {
  const isLabelActive = ref(false);
  const visibleItems = ref(false);
  const isSearchActive = ref(params.isSearchActive);

  const showItems = () => {
    visibleItems.value = true;
  };

  const hideItems = () => {
    visibleItems.value = false;
  };

  const addClassIn = () => {
    isLabelActive.value = true;
    showItems();
  };

  const removeClassIn = () => {
    isLabelActive.value = false;
    isSearchActive.value = false;
    hideItems();
  };

  const toggle = () => {
    if (isSearchActive.value) {
      isLabelActive.value = true;
      visibleItems.value = true;
    } else {
      isLabelActive.value = !isLabelActive.value;
      visibleItems.value = !visibleItems.value;
    }
  };

  const getSelected = (id: string | number) => {
    if (!params.modelValue.value.length) {
      return false;
    }

    return params.modelValue.value.find((item: any) => {
      return item.id === id;
    });
  };

  return {
    isLabelActive,
    visibleItems,

    addClassIn,
    removeClassIn,

    showItems,
    hideItems,

    toggle,

    getSelected,
  };
}
